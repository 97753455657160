(function(Way2web) {
    'use strict';

    Way2web.onDomReady = function() {
        if ($('.js-wik-input').length > 0) {
            Way2web.calculate.init();
        }

        Way2web.iframes();
    };

    // use 3rd party library for resizing iframe to the height of the content
    // https://github.com/davidjbradshaw/iframe-resizer
    //
    // this library needs additional javasript on iframe source
    // see: https://github.com/davidjbradshaw/iframe-resizer#getting-started
    Way2web.iframes = function() {
        $('iframe').iFrameResize();
    };

    Way2web.calculate = {
        init: function() {
            $('.js-wik-input').change(Way2web.calculate.update);
            $('.js-wik-calc').click(Way2web.calculate.update);
        },
        update: function() {
            var value = $('.js-wik-input').val();
            value = value.toNumber().round(2);
            $('.js-wik-input').val(value);
            var calculator = Way2web.wik(value);
            var results = calculator.run();
            Way2web.calculate.show(results);
        },
        show: function(results) {
            var item;
            var html = '<table>';
            html+='<tr><td>Van</td><td>tot</td><td>percentage</td><td>kosten</td></tr>';
            for (var i = 0; i < results.offset.length; i++) {
                item = results.offset[i];
                html += '<tr>';
                html += '<td>' + item.min + '</td>';
                html += '<td>' + item.max + '</td>';
                html += '<td>' + item.percentage + '</td>';
                html += '<td>&euro; ' + item.costs.toFixed(2) + '</td>';
                html += '</tr>';
            }
            html += '</table>';

            html += '<div class="well">';
            html += '<h3>Incassokosten:</h3>';
            html += '<dl class="dl">';
            html += '<dt>Totaal incassokosten ex BTW</dt><dd>€ ' + results.costs.toFixed(2) + '</dd>';
            html += '<dt>Totaal incassokosten incl 19% BTW</dt><dd>€ ' + (results.costs + results.vat1).toFixed(2) + '</dd>';
            html += '<dt>Totaal incassokosten incl 21% BTW</dt><dd>€ ' + (results.costs + results.vat2).toFixed(2) + '</dd>';
            html += '</dl>';
            html += '</div>';
            $('.js-wik-output').html(html);
        }
    }

    Way2web.wik = function(price) {
        var minimum = 40,
            maximum = 6775,
            vat1    = .19,
            vat2    = .21,
            offset  = [
                {min: 0,      max: 2500, percentage: 0.15},
                {min: 2500,   max: 5000, percentage: 0.1},
                {min: 5000,   max: 10000, percentage: 0.05},
                {min: 10000,  max: 200000, percentage: 0.01},
                {min: 200000, max: 1000000, percentage: 0.005}
            ];

        var calculator = {
            price: 0,
            costs: 0,
            vat1:  0,
            vat2:  0,
            offset: offset,

            loop: function(current, index) {
                var nextOffset        = 0,
                    extra             = 0;

                if (current.max > 0) {
                    nextOffset = current.max;
                } else {
                    nextOffset = calculator.price;
                }

                if (calculator.price > current.min && calculator.price < nextOffset) {
                    extra = (calculator.price - current.min) * current.percentage;
                } else if (price > current.min) {
                    extra = (nextOffset - current.min) * current.percentage;
                }

                if (extra < minimum && index == 0) {
                    extra = minimum;
                }

                if (current.max >= 1000000) {
                    calculator.offset[index].max = '&#8734;';
                }

                calculator.costs += extra;

                calculator.offset[index].costs = extra;
            },

            run: function() {
                calculator.costs = 0;
                calculator.calculate().fixLimits().calcVat();
                return this;
            },

            calculate: function() {
                offset.map(calculator.loop);
                return this;
            },

            calcVat: function() {
                calculator.vat1 = calculator.costs * vat1;
                calculator.vat2 = calculator.costs * vat2;
                return this;
            },

            fixLimits: function() {
                if (calculator.costs < minimum) {
                    calculator.costs = minimum;
                }
                if (calculator.costs > maximum) {
                    calculator.costs = maximum;
                }
                return this;
            }
        };

        calculator.price = price;

        return calculator;
    };

    Number.prototype.round = function(places){
         return Number(this.toFixed(places));
    }

    String.prototype.toNumber = function(places){
         var numbers = this.match(/[0-9]+/g);
         var price = '';
         if (numbers) {
             price += numbers[0];
             if (numbers.length > 1) {
                 price += '.' + numbers[1];
             }
         } else {
             price = 0;
         }
         return parseFloat(price);
    }

})(window.Way2web = window.Way2web || {});

$(document).ready(Way2web.onDomReady);
