/*global FastClick*/

'use strict';

(function($, window, document) {

    $(document).ready(function() {

        var $html = $('html'),
            $window = $(window),
            $main = $('main'),
            $covers = $('.cover'),
            $qas = $('.qa'),
            $modals = $('.js-open-modal'),
            $selects = $('select'),
            $billboardBackgrounds = $('.billboard__backgrounds'),

            overlayMenuActive = false;

        FastClick.attach(document.body);

        $window.on('resize', function() {

        });

        $main.fitVids();

        $covers.each(function() {
            var $self = $(this),
                $item = $self.find('.cover__item');

            $item.imageScale({
                parent: $self,
                align: $item.data('cover-align')
            });
        });

        $qas.each(function() {
            var $self = $(this),
                $a = $self.find('.qa__a');

            if ($self.hasClass('is-open')) {
                $a.show();
            }

            $self.on('click', function() {
                if ($self.hasClass('is-open')) {
                    $self.removeClass('is-open');
                    $a.slideUp();
                } else {
                    $self.addClass('is-open');
                    $a.slideDown();
                }
            });
        });

        $modals.each(function() {
            var $self = $(this),
                defaults = {
                    type: 'inline',
                    midClick: true,
                    closeOnBgClick: true,
                    closeMarkup: '<a href="#" title="%title%" class="mfp-close"><i class="icon icon--cross"></i></a>'
                };

            var options = $.extend({}, defaults, $self.data('magnific-popup'));

            $self.magnificPopup(options);
        });

        $billboardBackgrounds.each(function() {
            var $self = $(this);

            $self.find('.billboard__backgrounds__item:gt(0)').hide();

            setInterval(function() {
                $self.find('.billboard__backgrounds__item:first-child').fadeOut(2000)
                    .next('.billboard__backgrounds__item').fadeIn(2000)
                    .end().appendTo($self);
            }, 6000);
        });

        $selects.each(function() {
            var $self = $(this);

            if (!$self.parent().hasClass('select')) {
                $self.wrap('<div class="select"></div>');
            }
        });


        $('.navbar__hamburger').on('click', function() {
            var $self = $(this),
                $hamburger = $self.find('.hamburger');

            if (overlayMenuActive) {
                $('#overlay-menu').removeClass('is-active');
                $html.removeClass('no-scroll');
                $hamburger.removeClass('is-active');

                overlayMenuActive = false;
            } else {
                $('#overlay-menu').addClass('is-active');
                $html.addClass('no-scroll');
                $hamburger.addClass('is-active');

                overlayMenuActive = true;
            }
        });

    });

}(window.jQuery, window, document));
