(function (Helper) {

    Helper.validateEmail = function (email) {

        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);

    };

    Helper.bootstrapNormalizeCarouselHeights = function () {

        $.fn.carouselHeights = function() {

            var items = $(this), //grab all slides
                heights = [], //create empty array to store height values
                tallest; //create variable to make note of the tallest slide

            var normalizeHeights = function() {

                items.each(function() { //add heights to array
                    heights.push($(this).height());
                });
                tallest = Math.max.apply(null, heights); //cache largest value
                items.each(function() {
                    $(this).css('min-height',tallest + 'px');
                });
            };

            normalizeHeights();

            $(window).on('resize orientationchange', function () {
                //reset vars
                tallest = 0;
                heights.length = 0;

                items.each(function() {
                    $(this).css('min-height','0'); //reset min-height
                });
                normalizeHeights(); //run it again
            });

        };

    };
}(window.Helper = window.Helper || {}));
