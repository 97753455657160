(function(Affix) {
    'use strict';

    Affix.init = function(){
        // only affix on desk
        if(!verge.mq(Way2web.config.breakpoints.desk)){
            return;
        }

        var affixElement = $('.js-affix');

        if (!affixElement.length) {
            return;
        }

        var affixElementHeight = affixElement.outerHeight();
        var startAffixAt = affixElement.offset().top;

        // get the current body top padding
        // because we will modify it when affixing
        // when we are done affixing, we will want to restore it
        var body = $('body');
        var bodyPadding = body.css('paddingTop');

        $(window).scroll(function(){
            if ($(window).scrollTop() > startAffixAt){
                body.css('paddingTop', affixElementHeight);
                affixElement.addClass('is-affixed');
            }else{
                body.css('paddingTop', bodyPadding);
                affixElement.removeClass('is-affixed');
            }
        });
    };

})(window.Way2web.Affix = window.Way2web.Affix || {});
